import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import casinolistStyle from "./Casinolist.module.css"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

class Pikakasinolist extends React.Component {
	componentDidMount() {
		var lisa = document.getElementsByClassName("tab-lisa");
		var bonus = document.getElementsByClassName("tab-bonus");
		var i;
		for (i = 0; i < lisa.length; i++) {
			lisa[i].addEventListener("click", function() {
				this.classList.toggle("active");
				if (this.parentNode.nextElementSibling.childNodes[0].style.display === "block") {
					this.parentNode.nextElementSibling.childNodes[0].style.display = "none";
				} else {
					this.parentNode.nextElementSibling.childNodes[0].style.display = "block";
					this.parentNode.nextElementSibling.childNodes[1].style.display = "none";
					this.nextElementSibling.classList.remove("active");
				}
			});
		}
		var j;
		for (j = 0; j < bonus.length; j++) {
			bonus[j].addEventListener("click", function() {
				this.classList.toggle("active");
				if (this.parentNode.nextElementSibling.childNodes[1].style.display === "block") {
					this.parentNode.nextElementSibling.childNodes[1].style.display = "none";
				} else {
					this.parentNode.nextElementSibling.childNodes[1].style.display = "block";
					this.parentNode.nextElementSibling.childNodes[0].style.display = "none";
					this.previousElementSibling.classList.remove("active");
				}
			});
		}					

		var talletus = document.getElementsByClassName("talletus");
		var o;
		for (o = 0; o < talletus.length; o++) {
			var trustly = "<img src='/images/maksu/trustly.webp' alt='trustly' class='lazyload'/>"
			var euteller = "<img src='/images/maksu/euteller.webp'  alt='euteller' class='lazyload'/>"
			var visa = "<img src='/images/maksu/visa.webp' alt='visa' class='lazyload'/>"
			var visaelectron = "<img src='/images/maksu/visa-electron.webp' alt='visaelectron' class='lazyload'/>"
			var skrill = "<img src='/images/maksu/skrill.webp' alt='skrill' class='lazyload'/>"
			var mastercard = "<img src='/images/maksu/mastercard.webp' alt='mastercard' class='lazyload'/>"
			var maestro = "<img src='/images/maksu/maestro.webp' alt='maestro' class='lazyload'/>"
			var neteller = "<img src='/images/maksu/neteller.webp' alt='neteller' class='lazyload'/>"
			var paysafecard = "<img src='/images/maksu/paysafecard.webp' alt='paysafecard' class='lazyload'/>"
			var zimpler = "<img src='/images/maksu/zimpler.webp' alt='zimpler' class='lazyload'/>"
			var str = talletus[o].innerHTML;

			var res = str.replace("Trustly", trustly).replace("Euteller", euteller).replace("Visa", visa).replace("Visa Electron", visaelectron).replace("Skrill", skrill).replace("Mastercard", mastercard).replace("Maestro", maestro).replace("Neteller", neteller).replace("Paysafecard", paysafecard).replace("Zimpler", zimpler);
			talletus[o].innerHTML = res;
		}

		var tahdet = document.getElementsByClassName("tahdet");
		var t;
		for (t = 0; t < tahdet.length; t++) {
			var two = "<img src='/images/star2.webp' alt='2' class='lazyload'/>"
			var twohalf = "<img src='/images/star2half.webp' alt='kaksi' class='lazyload'/>"
			var three = "<img src='/images/star3.webp' alt='kolme' class='lazyload'/>"
			var threehalf = "<img src='/images/star3half.webp' alt='kolme' class='lazyload'/>"
			var four = "<img src='/images/star4.webp' alt='neljä' class='lazyload'/>"
			var fourhalf = "<img src='/images/star4half.webp' alt='neljä' class='lazyload'/>"
			var five = "<img src='/images/star5.webp' alt='viisi' class='lazyload'/>"
			var rate = tahdet[t].innerHTML;

			var star = rate.replace("2", two).replace("Kaksi puoli", twohalf).replace("3", three).replace("Kolme puoli", threehalf).replace("4", four).replace("Neljä puoli", fourhalf).replace("5", five);
			tahdet[t].innerHTML = star;
		}
	}

	render() {
		return(
			<StaticQuery 
				query = {graphql`
				query {
					megarushlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Megarush - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					casombielista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Casombie - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					lucklandlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Luckland - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					playclublista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Play Club - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					cosmicslotlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Cosmic Slot - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					fezbetlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Fezbet - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					jackiejackpotlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Jackie Jackpot - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					kazoomlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Kazoom - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					mrplaylista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Mr Play - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					refuellista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Refuel Casino - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					spelalista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Spela - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					winotalista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Winota - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					zetcasinolista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Zet Casino - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					neonvegaslista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "NeonVegas - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					nanolista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Nano Casino - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					kaksykslista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "21.com - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					nitrolista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Nitro Casino - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					vauhtilista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Vauhti - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					mountgoldlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Mount Gold - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					griffonlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Griffon Casino - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					casinolablista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Casinolab - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					racecasinolista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Race Casino - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					casinoplanetlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Casino Planet - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					pelaalista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Pelaa - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					kassulista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Kassu - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					casoolalista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Casoola - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					frankfredlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "FrankFred - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					busterbankslista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Buster Banks - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					slothunterlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Slothunter - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					wazambalista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Wazamba - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					casinobudlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Casinobud - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					gringoslista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "5Gringos Casino - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					cadabruslista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Cadabrus - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					ottokasinolista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Otto Kasino - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					klirrlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Klirr - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					metalcasinolista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Metal Casino - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					galaksinolista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Galaksino - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					turbovegaslista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "TurboVegas - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					comeonlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "ComeOn! - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					cashmiolista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Cashmio - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					amunralista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "AmunRa - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					loistokasinolista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Loistokasino - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					simppelilista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Simppeli - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					supernopealista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "SuperNopea - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					inoutcasinolista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "In&Out Casino - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					sevensignslista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "7Signs - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					crazyfoxlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Crazy Fox Casino - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					nopeampilista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Nopeampi - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					scandibetlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Scandibet - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
					gslotlista: contentfulListojenMuuttuvatTiedot (kasinonNimi: {eq: "Gslot - listatiedot"}) {
						kasinonNimi
						kasinonListanimi
						bonus
						ilmaiskierrokset
						tahtiarvostelu
						listalogo {
							title
							file {
								url
							}
						}
						afflink
						lisatiedotpikakasino {
							json
							}
						bonustiedotpikakasinot {
							json
						}
					}
				}`}
				render={data => (
					<table>
					<tbody>
						{/* Megarush */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.megarushlista.listalogo.file.url} alt={data.megarushlista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.megarushlista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.megarushlista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.megarushlista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.megarushlista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.megarushlista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.megarushlista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.megarushlista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Casombie */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.casombielista.listalogo.file.url} alt={data.casombielista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.casombielista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.casombielista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.casombielista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.casombielista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.casombielista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.casombielista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.casombielista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Luckland */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.lucklandlista.listalogo.file.url} alt={data.lucklandlista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.lucklandlista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.lucklandlista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.lucklandlista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.lucklandlista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.lucklandlista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.lucklandlista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.lucklandlista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Play club */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.playclublista.listalogo.file.url} alt={data.playclublista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.playclublista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.playclublista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.playclublista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.playclublista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.playclublista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.playclublista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.playclublista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Cosmic Slot */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.cosmicslotlista.listalogo.file.url} alt={data.cosmicslotlista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.cosmicslotlista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.cosmicslotlista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.cosmicslotlista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.cosmicslotlista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.cosmicslotlista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.cosmicslotlista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.cosmicslotlista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Fezbet */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.fezbetlista.listalogo.file.url} alt={data.fezbetlista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.fezbetlista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.fezbetlista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.fezbetlista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.fezbetlista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.fezbetlista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.fezbetlista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.fezbetlista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Jackie Jackpot */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.jackiejackpotlista.listalogo.file.url} alt={data.jackiejackpotlista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.jackiejackpotlista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.jackiejackpotlista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.jackiejackpotlista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.jackiejackpotlista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.jackiejackpotlista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.jackiejackpotlista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.jackiejackpotlista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Kazoom */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.kazoomlista.listalogo.file.url} alt={data.kazoomlista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.kazoomlista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.kazoomlista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.kazoomlista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.kazoomlista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.kazoomlista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.kazoomlista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.kazoomlista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Mr Play */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.mrplaylista.listalogo.file.url} alt={data.mrplaylista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.mrplaylista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.mrplaylista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.mrplaylista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.mrplaylista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.mrplaylista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.mrplaylista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.mrplaylista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Refuel Casino */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.refuellista.listalogo.file.url} alt={data.refuellista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.refuellista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.refuellista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.refuellista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.refuellista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.refuellista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.refuellista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.refuellista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Spela */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.spelalista.listalogo.file.url} alt={data.spelalista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.spelalista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.spelalista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.spelalista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.spelalista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.spelalista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.spelalista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.spelalista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Winota */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.winotalista.listalogo.file.url} alt={data.winotalista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.winotalista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.winotalista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.winotalista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.winotalista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.winotalista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.winotalista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.winotalista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Zet Casino */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.zetcasinolista.listalogo.file.url} alt={data.zetcasinolista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.zetcasinolista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.zetcasinolista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.zetcasinolista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.zetcasinolista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.zetcasinolista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.zetcasinolista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.zetcasinolista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Neonvegas */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.neonvegaslista.listalogo.file.url} alt={data.neonvegaslista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.neonvegaslista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.neonvegaslista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.neonvegaslista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.neonvegaslista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.neonvegaslista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.neonvegaslista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.neonvegaslista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Nano Casino */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.nanolista.listalogo.file.url} alt={data.nanolista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.nanolista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.nanolista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.nanolista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.nanolista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.nanolista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.nanolista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.nanolista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* 21.com */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.kaksykslista.listalogo.file.url} alt={data.kaksykslista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.kaksykslista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.kaksykslista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.kaksykslista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.kaksykslista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.kaksykslista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.kaksykslista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.kaksykslista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Nitro Casino */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.nitrolista.listalogo.file.url} alt={data.nitrolista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.nitrolista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.nitrolista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.nitrolista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.nitrolista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.nitrolista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.nitrolista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.nitrolista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Vauhti */}
<tr className={casinolistStyle.casino}>
<td className={casinolistStyle.top}>
    <div className={casinolistStyle.logo}>
        <img data-src={data.vauhtilista.listalogo.file.url} alt={data.vauhtilista.listalogo.title} className="lazyload"/>
        <div className={casinolistStyle.name}>
            <h5 className={casinolistStyle.title}>{data.vauhtilista.kasinonListanimi}</h5>
            <span className={`${casinolistStyle.rate} tahdet`}>{data.vauhtilista.tahtiarvostelu}</span>
        </div>
    </div>
    <div className={casinolistStyle.content}>
        <div className={casinolistStyle.contentBox}>
            <div className={casinolistStyle.contentInfo}>
                <div className={casinolistStyle.offerItem}>
                    <i>Bonukset:</i>
                    <h4>{data.vauhtilista.bonus}</h4>
                </div>
                <div className={casinolistStyle.offerItem}>
                    <i>Ilmaiskierrokset:</i>
                    <h4>{data.vauhtilista.ilmaiskierrokset}</h4>
                </div>
            </div>
            <div className={casinolistStyle.contentBtn}>
                <a className={casinolistStyle.btn} href={data.vauhtilista.afflink} target="_blank">Kasinolle</a>
            </div>
        </div>
    </div>
</td>
<td className={casinolistStyle.bottom}>
    <div className={casinolistStyle.pikaexpand}>
        <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
        <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
    </div>
    <div className={casinolistStyle.expandContent}>
        <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.vauhtilista.lisatiedotpikakasino.json)}</div>
        <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.vauhtilista.bonustiedotpikakasinot.json)}</div>
    </div>
</td>
</tr>
{/* Mount Gold */}
<tr className={casinolistStyle.casino}>
<td className={casinolistStyle.top}>
    <div className={casinolistStyle.logo}>
        <img data-src={data.mountgoldlista.listalogo.file.url} alt={data.mountgoldlista.listalogo.title} className="lazyload"/>
        <div className={casinolistStyle.name}>
            <h5 className={casinolistStyle.title}>{data.mountgoldlista.kasinonListanimi}</h5>
            <span className={`${casinolistStyle.rate} tahdet`}>{data.mountgoldlista.tahtiarvostelu}</span>
        </div>
    </div>
    <div className={casinolistStyle.content}>
        <div className={casinolistStyle.contentBox}>
            <div className={casinolistStyle.contentInfo}>
                <div className={casinolistStyle.offerItem}>
                    <i>Bonukset:</i>
                    <h4>{data.mountgoldlista.bonus}</h4>
                </div>
                <div className={casinolistStyle.offerItem}>
                    <i>Ilmaiskierrokset:</i>
                    <h4>{data.mountgoldlista.ilmaiskierrokset}</h4>
                </div>
            </div>
            <div className={casinolistStyle.contentBtn}>
                <a className={casinolistStyle.btn} href={data.mountgoldlista.afflink} target="_blank">Kasinolle</a>
            </div>
        </div>
    </div>
</td>
<td className={casinolistStyle.bottom}>
    <div className={casinolistStyle.pikaexpand}>
        <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
        <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
    </div>
    <div className={casinolistStyle.expandContent}>
        <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.mountgoldlista.lisatiedotpikakasino.json)}</div>
        <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.mountgoldlista.bonustiedotpikakasinot.json)}</div>
    </div>
</td>
</tr>
{/* Griffon Casino */}
<tr className={casinolistStyle.casino}>
<td className={casinolistStyle.top}>
    <div className={casinolistStyle.logo}>
        <img data-src={data.griffonlista.listalogo.file.url} alt={data.griffonlista.listalogo.title} className="lazyload"/>
        <div className={casinolistStyle.name}>
            <h5 className={casinolistStyle.title}>{data.griffonlista.kasinonListanimi}</h5>
            <span className={`${casinolistStyle.rate} tahdet`}>{data.griffonlista.tahtiarvostelu}</span>
        </div>
    </div>
    <div className={casinolistStyle.content}>
        <div className={casinolistStyle.contentBox}>
            <div className={casinolistStyle.contentInfo}>
                <div className={casinolistStyle.offerItem}>
                    <i>Bonukset:</i>
                    <h4>{data.griffonlista.bonus}</h4>
                </div>
                <div className={casinolistStyle.offerItem}>
                    <i>Ilmaiskierrokset:</i>
                    <h4>{data.griffonlista.ilmaiskierrokset}</h4>
                </div>
            </div>
            <div className={casinolistStyle.contentBtn}>
                <a className={casinolistStyle.btn} href={data.griffonlista.afflink} target="_blank">Kasinolle</a>
            </div>
        </div>
    </div>
</td>
<td className={casinolistStyle.bottom}>
    <div className={casinolistStyle.pikaexpand}>
        <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
        <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
    </div>
    <div className={casinolistStyle.expandContent}>
        <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.griffonlista.lisatiedotpikakasino.json)}</div>
        <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.griffonlista.bonustiedotpikakasinot.json)}</div>
    </div>
</td>
</tr>
{/* Casinolab */}
<tr className={casinolistStyle.casino}>
<td className={casinolistStyle.top}>
    <div className={casinolistStyle.logo}>
        <img data-src={data.casinolablista.listalogo.file.url} alt={data.casinolablista.listalogo.title} className="lazyload"/>
        <div className={casinolistStyle.name}>
            <h5 className={casinolistStyle.title}>{data.casinolablista.kasinonListanimi}</h5>
            <span className={`${casinolistStyle.rate} tahdet`}>{data.casinolablista.tahtiarvostelu}</span>
        </div>
    </div>
    <div className={casinolistStyle.content}>
        <div className={casinolistStyle.contentBox}>
            <div className={casinolistStyle.contentInfo}>
                <div className={casinolistStyle.offerItem}>
                    <i>Bonukset:</i>
                    <h4>{data.casinolablista.bonus}</h4>
                </div>
                <div className={casinolistStyle.offerItem}>
                    <i>Ilmaiskierrokset:</i>
                    <h4>{data.casinolablista.ilmaiskierrokset}</h4>
                </div>
            </div>
            <div className={casinolistStyle.contentBtn}>
                <a className={casinolistStyle.btn} href={data.casinolablista.afflink} target="_blank">Kasinolle</a>
            </div>
        </div>
    </div>
</td>
<td className={casinolistStyle.bottom}>
    <div className={casinolistStyle.pikaexpand}>
        <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
        <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
    </div>
    <div className={casinolistStyle.expandContent}>
        <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.casinolablista.lisatiedotpikakasino.json)}</div>
        <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.casinolablista.bonustiedotpikakasinot.json)}</div>
    </div>
</td>
</tr>
{/* Race Casino */}
<tr className={casinolistStyle.casino}>
<td className={casinolistStyle.top}>
    <div className={casinolistStyle.logo}>
        <img data-src={data.racecasinolista.listalogo.file.url} alt={data.racecasinolista.listalogo.title} className="lazyload"/>
        <div className={casinolistStyle.name}>
            <h5 className={casinolistStyle.title}>{data.racecasinolista.kasinonListanimi}</h5>
            <span className={`${casinolistStyle.rate} tahdet`}>{data.racecasinolista.tahtiarvostelu}</span>
        </div>
    </div>
    <div className={casinolistStyle.content}>
        <div className={casinolistStyle.contentBox}>
            <div className={casinolistStyle.contentInfo}>
                <div className={casinolistStyle.offerItem}>
                    <i>Bonukset:</i>
                    <h4>{data.racecasinolista.bonus}</h4>
                </div>
                <div className={casinolistStyle.offerItem}>
                    <i>Ilmaiskierrokset:</i>
                    <h4>{data.racecasinolista.ilmaiskierrokset}</h4>
                </div>
            </div>
            <div className={casinolistStyle.contentBtn}>
                <a className={casinolistStyle.btn} href={data.racecasinolista.afflink} target="_blank">Kasinolle</a>
            </div>
        </div>
    </div>
</td>
<td className={casinolistStyle.bottom}>
    <div className={casinolistStyle.pikaexpand}>
        <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
        <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
    </div>
    <div className={casinolistStyle.expandContent}>
        <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.racecasinolista.lisatiedotpikakasino.json)}</div>
        <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.racecasinolista.bonustiedotpikakasinot.json)}</div>
    </div>
</td>
</tr>
{/* Casino Planet */}
<tr className={casinolistStyle.casino}>
<td className={casinolistStyle.top}>
    <div className={casinolistStyle.logo}>
        <img data-src={data.casinoplanetlista.listalogo.file.url} alt={data.casinoplanetlista.listalogo.title} className="lazyload"/>
        <div className={casinolistStyle.name}>
            <h5 className={casinolistStyle.title}>{data.casinoplanetlista.kasinonListanimi}</h5>
            <span className={`${casinolistStyle.rate} tahdet`}>{data.casinoplanetlista.tahtiarvostelu}</span>
        </div>
    </div>
    <div className={casinolistStyle.content}>
        <div className={casinolistStyle.contentBox}>
            <div className={casinolistStyle.contentInfo}>
                <div className={casinolistStyle.offerItem}>
                    <i>Bonukset:</i>
                    <h4>{data.casinoplanetlista.bonus}</h4>
                </div>
                <div className={casinolistStyle.offerItem}>
                    <i>Ilmaiskierrokset:</i>
                    <h4>{data.casinoplanetlista.ilmaiskierrokset}</h4>
                </div>
            </div>
            <div className={casinolistStyle.contentBtn}>
                <a className={casinolistStyle.btn} href={data.casinoplanetlista.afflink} target="_blank">Kasinolle</a>
            </div>
        </div>
    </div>
</td>
<td className={casinolistStyle.bottom}>
    <div className={casinolistStyle.pikaexpand}>
        <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
        <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
    </div>
    <div className={casinolistStyle.expandContent}>
        <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.casinoplanetlista.lisatiedotpikakasino.json)}</div>
        <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.casinoplanetlista.bonustiedotpikakasinot.json)}</div>
    </div>
</td>
</tr>
{/* Pelaa */}
<tr className={casinolistStyle.casino}>
<td className={casinolistStyle.top}>
    <div className={casinolistStyle.logo}>
        <img data-src={data.pelaalista.listalogo.file.url} alt={data.pelaalista.listalogo.title} className="lazyload"/>
        <div className={casinolistStyle.name}>
            <h5 className={casinolistStyle.title}>{data.pelaalista.kasinonListanimi}</h5>
            <span className={`${casinolistStyle.rate} tahdet`}>{data.pelaalista.tahtiarvostelu}</span>
        </div>
    </div>
    <div className={casinolistStyle.content}>
        <div className={casinolistStyle.contentBox}>
            <div className={casinolistStyle.contentInfo}>
                <div className={casinolistStyle.offerItem}>
                    <i>Bonukset:</i>
                    <h4>{data.pelaalista.bonus}</h4>
                </div>
                <div className={casinolistStyle.offerItem}>
                    <i>Ilmaiskierrokset:</i>
                    <h4>{data.pelaalista.ilmaiskierrokset}</h4>
                </div>
            </div>
            <div className={casinolistStyle.contentBtn}>
                <a className={casinolistStyle.btn} href={data.pelaalista.afflink} target="_blank">Kasinolle</a>
            </div>
        </div>
    </div>
</td>
<td className={casinolistStyle.bottom}>
    <div className={casinolistStyle.pikaexpand}>
        <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
        <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
    </div>
    <div className={casinolistStyle.expandContent}>
        <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.pelaalista.lisatiedotpikakasino.json)}</div>
        <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.pelaalista.bonustiedotpikakasinot.json)}</div>
    </div>
</td>
</tr>
{/* Kassu */}
<tr className={casinolistStyle.casino}>
<td className={casinolistStyle.top}>
    <div className={casinolistStyle.logo}>
        <img data-src={data.kassulista.listalogo.file.url} alt={data.kassulista.listalogo.title} className="lazyload"/>
        <div className={casinolistStyle.name}>
            <h5 className={casinolistStyle.title}>{data.kassulista.kasinonListanimi}</h5>
            <span className={`${casinolistStyle.rate} tahdet`}>{data.kassulista.tahtiarvostelu}</span>
        </div>
    </div>
    <div className={casinolistStyle.content}>
        <div className={casinolistStyle.contentBox}>
            <div className={casinolistStyle.contentInfo}>
                <div className={casinolistStyle.offerItem}>
                    <i>Bonukset:</i>
                    <h4>{data.kassulista.bonus}</h4>
                </div>
                <div className={casinolistStyle.offerItem}>
                    <i>Ilmaiskierrokset:</i>
                    <h4>{data.kassulista.ilmaiskierrokset}</h4>
                </div>
            </div>
            <div className={casinolistStyle.contentBtn}>
                <a className={casinolistStyle.btn} href={data.kassulista.afflink} target="_blank">Kasinolle</a>
            </div>
        </div>
    </div>
</td>
<td className={casinolistStyle.bottom}>
    <div className={casinolistStyle.pikaexpand}>
        <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
        <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
    </div>
    <div className={casinolistStyle.expandContent}>
        <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.kassulista.lisatiedotpikakasino.json)}</div>
        <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.kassulista.bonustiedotpikakasinot.json)}</div>
    </div>
</td>
</tr>
{/* Casoola */}
<tr className={casinolistStyle.casino}>
<td className={casinolistStyle.top}>
    <div className={casinolistStyle.logo}>
        <img data-src={data.casoolalista.listalogo.file.url} alt={data.casoolalista.listalogo.title} className="lazyload"/>
        <div className={casinolistStyle.name}>
            <h5 className={casinolistStyle.title}>{data.casoolalista.kasinonListanimi}</h5>
            <span className={`${casinolistStyle.rate} tahdet`}>{data.casoolalista.tahtiarvostelu}</span>
        </div>
    </div>
    <div className={casinolistStyle.content}>
        <div className={casinolistStyle.contentBox}>
            <div className={casinolistStyle.contentInfo}>
                <div className={casinolistStyle.offerItem}>
                    <i>Bonukset:</i>
                    <h4>{data.casoolalista.bonus}</h4>
                </div>
                <div className={casinolistStyle.offerItem}>
                    <i>Ilmaiskierrokset:</i>
                    <h4>{data.casoolalista.ilmaiskierrokset}</h4>
                </div>
            </div>
            <div className={casinolistStyle.contentBtn}>
                <a className={casinolistStyle.btn} href={data.casoolalista.afflink} target="_blank">Kasinolle</a>
            </div>
        </div>
    </div>
</td>
<td className={casinolistStyle.bottom}>
    <div className={casinolistStyle.pikaexpand}>
        <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
        <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
    </div>
    <div className={casinolistStyle.expandContent}>
        <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.casoolalista.lisatiedotpikakasino.json)}</div>
        <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.casoolalista.bonustiedotpikakasinot.json)}</div>
    </div>
</td>
</tr>
{/* Frank Fred */}
<tr className={casinolistStyle.casino}>
<td className={casinolistStyle.top}>
    <div className={casinolistStyle.logo}>
        <img data-src={data.frankfredlista.listalogo.file.url} alt={data.frankfredlista.listalogo.title} className="lazyload"/>
        <div className={casinolistStyle.name}>
            <h5 className={casinolistStyle.title}>{data.frankfredlista.kasinonListanimi}</h5>
            <span className={`${casinolistStyle.rate} tahdet`}>{data.frankfredlista.tahtiarvostelu}</span>
        </div>
    </div>
    <div className={casinolistStyle.content}>
        <div className={casinolistStyle.contentBox}>
            <div className={casinolistStyle.contentInfo}>
                <div className={casinolistStyle.offerItem}>
                    <i>Bonukset:</i>
                    <h4>{data.frankfredlista.bonus}</h4>
                </div>
                <div className={casinolistStyle.offerItem}>
                    <i>Ilmaiskierrokset:</i>
                    <h4>{data.frankfredlista.ilmaiskierrokset}</h4>
                </div>
            </div>
            <div className={casinolistStyle.contentBtn}>
                <a className={casinolistStyle.btn} href={data.frankfredlista.afflink} target="_blank">Kasinolle</a>
            </div>
        </div>
    </div>
</td>
<td className={casinolistStyle.bottom}>
    <div className={casinolistStyle.pikaexpand}>
        <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
        <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
    </div>
    <div className={casinolistStyle.expandContent}>
        <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.frankfredlista.lisatiedotpikakasino.json)}</div>
        <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.frankfredlista.bonustiedotpikakasinot.json)}</div>
    </div>
</td>
</tr>
{/* Buster Banks */}
<tr className={casinolistStyle.casino}>
<td className={casinolistStyle.top}>
    <div className={casinolistStyle.logo}>
        <img data-src={data.busterbankslista.listalogo.file.url} alt={data.busterbankslista.listalogo.title} className="lazyload"/>
        <div className={casinolistStyle.name}>
            <h5 className={casinolistStyle.title}>{data.busterbankslista.kasinonListanimi}</h5>
            <span className={`${casinolistStyle.rate} tahdet`}>{data.busterbankslista.tahtiarvostelu}</span>
        </div>
    </div>
    <div className={casinolistStyle.content}>
        <div className={casinolistStyle.contentBox}>
            <div className={casinolistStyle.contentInfo}>
                <div className={casinolistStyle.offerItem}>
                    <i>Bonukset:</i>
                    <h4>{data.busterbankslista.bonus}</h4>
                </div>
                <div className={casinolistStyle.offerItem}>
                    <i>Ilmaiskierrokset:</i>
                    <h4>{data.busterbankslista.ilmaiskierrokset}</h4>
                </div>
            </div>
            <div className={casinolistStyle.contentBtn}>
                <a className={casinolistStyle.btn} href={data.busterbankslista.afflink} target="_blank">Kasinolle</a>
            </div>
        </div>
    </div>
</td>
<td className={casinolistStyle.bottom}>
    <div className={casinolistStyle.pikaexpand}>
        <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
        <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
    </div>
    <div className={casinolistStyle.expandContent}>
        <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.busterbankslista.lisatiedotpikakasino.json)}</div>
        <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.busterbankslista.bonustiedotpikakasinot.json)}</div>
    </div>
</td>
</tr>
{/* Slothunter */}
<tr className={casinolistStyle.casino}>
<td className={casinolistStyle.top}>
    <div className={casinolistStyle.logo}>
        <img data-src={data.slothunterlista.listalogo.file.url} alt={data.slothunterlista.listalogo.title} className="lazyload"/>
        <div className={casinolistStyle.name}>
            <h5 className={casinolistStyle.title}>{data.slothunterlista.kasinonListanimi}</h5>
            <span className={`${casinolistStyle.rate} tahdet`}>{data.slothunterlista.tahtiarvostelu}</span>
        </div>
    </div>
    <div className={casinolistStyle.content}>
        <div className={casinolistStyle.contentBox}>
            <div className={casinolistStyle.contentInfo}>
                <div className={casinolistStyle.offerItem}>
                    <i>Bonukset:</i>
                    <h4>{data.slothunterlista.bonus}</h4>
                </div>
                <div className={casinolistStyle.offerItem}>
                    <i>Ilmaiskierrokset:</i>
                    <h4>{data.slothunterlista.ilmaiskierrokset}</h4>
                </div>
            </div>
            <div className={casinolistStyle.contentBtn}>
                <a className={casinolistStyle.btn} href={data.slothunterlista.afflink} target="_blank">Kasinolle</a>
            </div>
        </div>
    </div>
</td>
<td className={casinolistStyle.bottom}>
    <div className={casinolistStyle.pikaexpand}>
        <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
        <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
    </div>
    <div className={casinolistStyle.expandContent}>
        <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.slothunterlista.lisatiedotpikakasino.json)}</div>
        <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.slothunterlista.bonustiedotpikakasinot.json)}</div>
    </div>
</td>
</tr>
{/* Wazamba */}
<tr className={casinolistStyle.casino}>
<td className={casinolistStyle.top}>
    <div className={casinolistStyle.logo}>
        <img data-src={data.wazambalista.listalogo.file.url} alt={data.wazambalista.listalogo.title} className="lazyload"/>
        <div className={casinolistStyle.name}>
            <h5 className={casinolistStyle.title}>{data.wazambalista.kasinonListanimi}</h5>
            <span className={`${casinolistStyle.rate} tahdet`}>{data.wazambalista.tahtiarvostelu}</span>
        </div>
    </div>
    <div className={casinolistStyle.content}>
        <div className={casinolistStyle.contentBox}>
            <div className={casinolistStyle.contentInfo}>
                <div className={casinolistStyle.offerItem}>
                    <i>Bonukset:</i>
                    <h4>{data.wazambalista.bonus}</h4>
                </div>
                <div className={casinolistStyle.offerItem}>
                    <i>Ilmaiskierrokset:</i>
                    <h4>{data.wazambalista.ilmaiskierrokset}</h4>
                </div>
            </div>
            <div className={casinolistStyle.contentBtn}>
                <a className={casinolistStyle.btn} href={data.wazambalista.afflink} target="_blank">Kasinolle</a>
            </div>
        </div>
    </div>
</td>
<td className={casinolistStyle.bottom}>
    <div className={casinolistStyle.pikaexpand}>
        <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
        <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
    </div>
    <div className={casinolistStyle.expandContent}>
        <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.wazambalista.lisatiedotpikakasino.json)}</div>
        <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.wazambalista.bonustiedotpikakasinot.json)}</div>
    </div>
</td>
</tr>
{/* Casinobud */}
<tr className={casinolistStyle.casino}>
<td className={casinolistStyle.top}>
    <div className={casinolistStyle.logo}>
        <img data-src={data.casinobudlista.listalogo.file.url} alt={data.casinobudlista.listalogo.title} className="lazyload"/>
        <div className={casinolistStyle.name}>
            <h5 className={casinolistStyle.title}>{data.casinobudlista.kasinonListanimi}</h5>
            <span className={`${casinolistStyle.rate} tahdet`}>{data.casinobudlista.tahtiarvostelu}</span>
        </div>
    </div>
    <div className={casinolistStyle.content}>
        <div className={casinolistStyle.contentBox}>
            <div className={casinolistStyle.contentInfo}>
                <div className={casinolistStyle.offerItem}>
                    <i>Bonukset:</i>
                    <h4>{data.casinobudlista.bonus}</h4>
                </div>
                <div className={casinolistStyle.offerItem}>
                    <i>Ilmaiskierrokset:</i>
                    <h4>{data.casinobudlista.ilmaiskierrokset}</h4>
                </div>
            </div>
            <div className={casinolistStyle.contentBtn}>
                <a className={casinolistStyle.btn} href={data.casinobudlista.afflink} target="_blank">Kasinolle</a>
            </div>
        </div>
    </div>
</td>
<td className={casinolistStyle.bottom}>
    <div className={casinolistStyle.pikaexpand}>
        <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
        <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
    </div>
    <div className={casinolistStyle.expandContent}>
        <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.casinobudlista.lisatiedotpikakasino.json)}</div>
        <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.casinobudlista.bonustiedotpikakasinot.json)}</div>
    </div>
</td>
</tr>
						{/* 5Gringos */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.gringoslista.listalogo.file.url} alt={data.gringoslista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.gringoslista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.gringoslista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.gringoslista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.gringoslista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.gringoslista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.gringoslista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.gringoslista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Cadabrus */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.cadabruslista.listalogo.file.url} alt={data.cadabruslista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.cadabruslista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.cadabruslista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.cadabruslista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.cadabruslista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.cadabruslista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.cadabruslista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.cadabruslista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Otto Kasino*/}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.ottokasinolista.listalogo.file.url} alt={data.ottokasinolista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.ottokasinolista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.ottokasinolista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.ottokasinolista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.ottokasinolista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.ottokasinolista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.ottokasinolista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.ottokasinolista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Klirr Casino*/}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.klirrlista.listalogo.file.url} alt={data.klirrlista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.klirrlista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.klirrlista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.klirrlista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.klirrlista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.klirrlista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.klirrlista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.klirrlista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
							{/* Metal Casino*/}
							<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.metalcasinolista.listalogo.file.url} alt={data.metalcasinolista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.metalcasinolista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.metalcasinolista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.metalcasinolista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.metalcasinolista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.metalcasinolista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.metalcasinolista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.metalcasinolista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Galaksino */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.galaksinolista.listalogo.file.url} alt={data.galaksinolista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.galaksinolista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.galaksinolista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.galaksinolista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.galaksinolista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.galaksinolista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.galaksinolista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.galaksinolista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* TurboVegas */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.turbovegaslista.listalogo.file.url} alt={data.turbovegaslista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.turbovegaslista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.turbovegaslista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.turbovegaslista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.turbovegaslista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.turbovegaslista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.turbovegaslista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.turbovegaslista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* ComeOn! */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.comeonlista.listalogo.file.url} alt={data.comeonlista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.comeonlista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.comeonlista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.comeonlista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.comeonlista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.comeonlista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.comeonlista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.comeonlista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Cashmio */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.cashmiolista.listalogo.file.url} alt={data.cashmiolista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.cashmiolista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.cashmiolista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.cashmiolista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.cashmiolista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.cashmiolista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.cashmiolista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.cashmiolista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* AmunRa */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.amunralista.listalogo.file.url} alt={data.amunralista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.amunralista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.amunralista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.amunralista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.amunralista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.amunralista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.amunralista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.amunralista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Loistokasino */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.loistokasinolista.listalogo.file.url} alt={data.loistokasinolista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.loistokasinolista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.loistokasinolista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset</i>
												<h4>{data.loistokasinolista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset</i>
												<h4>{data.loistokasinolista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.loistokasinolista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.loistokasinolista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.loistokasinolista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Simppeli */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.simppelilista.listalogo.file.url} alt={data.simppelilista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.simppelilista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.simppelilista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset</i>
												<h4>{data.simppelilista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset</i>
												<h4>{data.simppelilista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.simppelilista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.simppelilista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.simppelilista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* supernopealista */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.supernopealista.listalogo.file.url} alt={data.supernopealista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.supernopealista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.supernopealista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.supernopealista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.supernopealista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.supernopealista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.supernopealista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.supernopealista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* InOut Casino */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.inoutcasinolista.listalogo.file.url} alt={data.inoutcasinolista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.inoutcasinolista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.inoutcasinolista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.inoutcasinolista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.inoutcasinolista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.inoutcasinolista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.inoutcasinolista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.inoutcasinolista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* 7Signs */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.sevensignslista.listalogo.file.url} alt={data.sevensignslista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.sevensignslista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.sevensignslista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.sevensignslista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.sevensignslista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.sevensignslista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.sevensignslista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.sevensignslista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Crazy Fox Casino */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.crazyfoxlista.listalogo.file.url} alt={data.crazyfoxlista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.crazyfoxlista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.crazyfoxlista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.crazyfoxlista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.crazyfoxlista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.crazyfoxlista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.crazyfoxlista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.crazyfoxlista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Nopeampi */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.nopeampilista.listalogo.file.url} alt={data.nopeampilista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.nopeampilista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.nopeampilista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.nopeampilista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.nopeampilista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.nopeampilista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.nopeampilista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.nopeampilista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Scandibet */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.scandibetlista.listalogo.file.url} alt={data.scandibetlista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.scandibetlista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.scandibetlista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.scandibetlista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.scandibetlista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.scandibetlista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.scandibetlista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.scandibetlista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
						{/* Gslot */}
						<tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={data.gslotlista.listalogo.file.url} alt={data.gslotlista.listalogo.title} className="lazyload"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{data.gslotlista.kasinonListanimi}</h5>
										<span className={`${casinolistStyle.rate} tahdet`}>{data.gslotlista.tahtiarvostelu}</span>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<i>Bonukset:</i>
												<h4>{data.gslotlista.bonus}</h4>
											</div>
											<div className={casinolistStyle.offerItem}>
												<i>Ilmaiskierrokset:</i>
												<h4>{data.gslotlista.ilmaiskierrokset}</h4>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={data.gslotlista.afflink} target="_blank">Kasinolle</a>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>
								<div className={casinolistStyle.pikaexpand}>
									<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
									<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
								</div>
								<div className={casinolistStyle.expandContent}>
									<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(data.gslotlista.lisatiedotpikakasino.json)}</div>
									<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(data.gslotlista.bonustiedotpikakasinot.json)}</div>
								</div>
							</td>
						</tr>
					</tbody>
					</table>
				)}
			/>
		)
	}
}

export default Pikakasinolist
